import React, {useState} from 'react';
import './index.scss';
import {graphql} from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import {PostPreview} from '../../components/PostPreview';
import {InstagramPosts} from '../../components/InstagramPosts';
import {PostCard} from '../../components/PostCard';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import _ from 'lodash';

const NewsAll = ({pageContext, data}) => {
    let [ countVisible, setCountVisible ] = useState(16);
    let posts = _.sortBy(pageContext.posts, [ 'createdDate' ]).reverse();
    let totalCount = posts.length;
    let [ firstPost, ...otherPosts ] = posts;
    // let instagramPosts = data.allInstaNode.edges.map(({node}) => ({
    //     ...node,
    //     permalink: `https://www.instagram.com/p/${node.id}/`,
    // }));
    let instagramPosts = []

    return (
        <Layout className={'NewsAll'} Footer={<Footer/>}>
            <SEO title="Home" keywords={[ `gatsby`, `application`, `react` ]}/>

            <div className="row">

                <div className='column all-posts'>
                    {firstPost && <PostPreview
                        type={firstPost.category}
                        heroPhoto={firstPost.hero && firstPost.hero.url}
                        heroFluid={firstPost.hero && firstPost.hero.large.childImageSharp.fluid}
                        title={firstPost.title}
                        desc={firstPost.template}
                        smallText={firstPost.smallText}
                        createdAt={firstPost.createdDate || firstPost.created_at}
                        url={`/news/posts/${firstPost.slug}`}
                    />}

                    <div className='row all-small-posts'>
                        {otherPosts.slice(0, countVisible - 1).map((post, index) => (
                            <PostCard type={post.category}
                                      photo={post.hero && post.hero.url}
                                      photoFluid={post.hero && post.hero.small.childImageSharp.fluid}
                                      title={post.title}
                                      desc={post.template}
                                      smallText={post.smallText}
                                      createdAt={post.createdDate || post.created_at}
                                      url={`/news/posts/${post.slug}`}
                                      key={index.toString()}/>))}


                    </div>
                    <div className="footer-small-posts">

                        {
                            countVisible < totalCount &&
                            <Button onClick={() => {
                                setCountVisible(countVisible + 15);

                                setTimeout(() => {
                                    document.lazyLoad && document.lazyLoad();
                                }, 40);
                            }}
                                    className="ButtonLoadMore">
                                Show More
                            </Button>
                        }
                    </div>
                </div>

                <div className='column instagram-roll '>
                    <InstagramPosts posts={_.sortBy(instagramPosts, [ 'timestamp' ]).reverse()}/>
                </div>

            </div>

        </Layout>
    );
};

// export const query = graphql`
// query {
//   allInstaNode {
//     edges {
//       node {
//         id
//         mediaType
//         preview
//         username
//         original
//         timestamp
//         caption
//       }
//     }
//   }
// }
// `;

export default NewsAll;
