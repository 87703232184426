import React from 'react';
import { Link } from "gatsby"
import './index.scss'
import PostType from "../PostType"
import Title from "../Title"
import Desc from "../Desc"
import DatePublication from "../DatePublication"
import Image from "../Image";
import Img from 'gatsby-image'
import {sliceText} from '../../pipes/text';


export function PostPreview ({type, heroPhoto, heroFluid, title, smallText, createdAt, url}) {
   let desc = sliceText(smallText, 300);

  return (
    <div className='PostPreview'>
      <PostType type={type}/>
      <Link to={url}>
        <Img className="Image" fluid={heroFluid} style={{ width: '100%' }}/>
      </Link>
      <Link to={url}>
        <Title.Medium>{title}</Title.Medium>
      </Link>
      <Desc.Normal> {desc}</Desc.Normal>
      <DatePublication createdAt={createdAt}/>
    </div>
  )
}
