import React from 'react';
import './index.scss'
import PostType from "../PostType"
import Title from "../Title"
import Desc from "../Desc"
import DatePublication from "../DatePublication"
import Image from "../Image";
import {Link} from 'gatsby'
import {sliceText} from '../../pipes/text';
import Img from 'gatsby-image'

const MAX_LENGTH_DESC = 120;

export function PostCard({type, photo, photoFluid, title, smallText, createdAt, url}) {
   // slide text;
   let desc = sliceText(smallText, MAX_LENGTH_DESC);

    return (
      <div className='PostCard'>
         <PostType type={type} children={ <DatePublication createdAt={createdAt}/>}/>
         <Link to={url}>
           <Img className="Image" fluid={photoFluid} style={{ width: '100%' }}  alt={title} />
         </Link>
         <Link to={url}>
            <Title.Small>{title}</Title.Small>
         </Link>
         <Desc.Small>
             {desc}
         </Desc.Small>
         <DatePublication createdAt={createdAt}/>
      </div>
   )
}
