import React from 'react';
import './index.scss'
import SplitterSymbol from "../SplitterSymbol";


export default function PostType({type, children}) {
   return (
      <div className='PostType'>
      <span>
       {type}
      </span>

         {children && <SplitterSymbol/>}
         {children}
      </div>
   )
}

PostType.ANNOUNCEMENT = 'ANNOUNCEMENT';
PostType.EVENT = 'EVENT';
PostType.PUBLICATION = 'PUBLICATION';