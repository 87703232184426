import React from 'react';
import './index.scss'
import PostType from "../PostType"
import Image from "../Image";
import Desc from "../Desc";
import {sliceText} from '../../pipes/text';

function Post(props) {
   let {original, username, caption, permalink} = props;

    return (<div className="InstagramPost">
       <a href={permalink} target={"_blank"}>


          <Image src={original} />


      <Desc.Small>
         @envelopead: {sliceText(caption, 50)}
      </Desc.Small>
       </a>
   </div>)
}

export function InstagramPosts ({posts = []}) {
  return (
    <div className='InstagramPosts' >
      {/*<PostType type={'instagram'}/>*/}
      {posts.map((post, key) => <Post {...post} key={key}/>)}
    </div>
  )
}
