



export const sliceText = (desc, maxLength) => {
    let text = (desc || '').toString();

    if (text.length <= maxLength) {
        return text
    }

    return text.slice(0, maxLength).trim().replace(/\.{0,3}$/, '...');
};
